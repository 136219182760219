<app-busantei-header [hasBack]="false">
  <img
    src="/assets/img/busantei_logo.png"
    src.lt-sm="/assets/img/busantei_logo_s.png"
  />

  <div class="language-wrapper">
    <img
      class="language-button"
      src="/assets/img/language.png"
      [mat-menu-trigger-for]="language"
    />
  </div>

  <mat-menu #language="matMenu">
    <button mat-menu-item (click)="onLanguageChange('ja')">
      <span>日本語</span>
    </button>
    <button mat-menu-item (click)="onLanguageChange('ko')">
      <span>한국어</span>
    </button>
  </mat-menu>
</app-busantei-header>

<router-outlet></router-outlet>

<div class="icon-container">
  <a
    class="instagram-icon"
    href="https://www.instagram.com/pusantei0208"
    target="_blank"
  >
    <img class="instagram-image" src="/assets/img/ic_insta.svg" />
  </a>

  <a class="facebook-icon">
    <img src="/assets/img/icon-facebook-blue.png" />
  </a>

  <a class="scroll-top-icon" (click)="onClickScrollTop()">
    <img src="/assets/img/icon-arrow-up-large-white.png" />
  </a>
</div>

<app-busantei-footer></app-busantei-footer>

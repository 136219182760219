export const environment = {
  production: false,
  apiServerUrl: 'https://tournity-api.dev.paywith.co.kr',
  serverUrl: '',
  // FIXME:
  packageName: 'kr.co.paywith.template',
  // FIXME:
  appStoreId: 0,
  // FIXME:
  webAppVersion: {
    AOS: { major: 0, minor: 0, patch: 0 },
    IOS: { major: 0, minor: 0, patch: 0 },
  },
  brandId: 106,
};

import { Component, ElementRef, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-busantei-layout',
  templateUrl: './busantei-layout.component.html',
  styleUrls: ['./busantei-layout.component.scss'],
})
export class BusanteiLayoutComponent implements OnInit {
  constructor(
    private elementRef: ElementRef,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {}

  onLanguageChange(lang: string): void {
    this.languageService.changeLang(lang);
  }

  onClickScrollTop(): void {
    const selfElement = <HTMLElement>this.elementRef.nativeElement;
    if (selfElement.parentElement.scrollTop > 0) {
      selfElement.parentElement.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      (<Document>selfElement.getRootNode()).documentElement.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }
}
